@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Josefin+Sans:wght@500&family=Roboto&display=swap");

:root {
  --main-color: #fec534;
  --main-font: "Josefin Sans", sans-serif;
}
* {
  scroll-behavior: smooth;
}

body {
  /* background-color: #be1e2e; */
  /* background-color: #000; */
  background-color: #111111;
}

p {
  font-family: var(--main-font);
}

/* =============== root assets starts =============== */
.text-main-color {
  color: var(--main-color);
}

.bg-main-color {
  background-color: var(--main-color);
}

.font-main {
  font-family: var(--main-font);
}

.yellow-outline-btn,
.yellow-outline-btn:focus {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0.8rem 0.4rem;
  border-radius: 0.5rem;
  transition: all 0.5s ease;
}
.yellow-outline-btn:hover {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  color: black;
}
.header-modal {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 0;
  border-bottom: none;
}

.white-outline-btn {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
  text-transform: uppercase;
  margin: 0.8rem 0.4rem;
  border-radius: 0.25rem;
  transition: all 0.5s ease;
}
.white-outline-btn:hover {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  color: black;
}
.popup-bar-text {
  background: rgb(59, 55, 55);
  color: white;
  padding: 0;
  text-align: center;
}
.modal-60h {
  height: 70%;
  max-height: none !important;
}
.popup-modal > .modal-content {
  border: 1px solid rgb(59, 55, 55);
}
.dark-outline-btn {
  border: 1px solid #000;
  color: #000;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0.8rem 0.4rem;
  border-radius: 0.25rem;
  transition: all 0.5s ease;
}
.dark-outline-btn:hover {
  border: 1px solid #000;
  background-color: #000;
  color: var(--main-color);
}

.heading {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
/* =============== root assets ends =============== */

.about-text-alignment {
  text-align: justify;
}

.header-section {
  z-index: 100;
  width: 100%;
  height: 50%;
  margin: 0;
  padding: 0;
  display: block;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.7s, opacity 0.7s ease;
}

.background-video {
  object-fit: "cover";
  width: "100%";
  height: "90vh";
}

.video-text-position {
  position: absolute;
  z-index: 1;
  left: 0%;
  top: 55%;
  width: 100%;
  color: white;
}

@media (max-width: 991px) {
  .video-text-position {
    position: relative;
    color: black;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .background-video {
    object-fit: "cover";
    width: "100%";
    height: "25vh";
  }
}

.bar {
  background: linear-gradient(
    -135deg,
    transparent 66px,
    rgba(0, 0, 0, 0.3) 66px
  );
  color: white;
  text-align: center;
  padding: 3rem 6rem;
}
.bar:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  background: url("../../public/assets/img/trans_corner.png") no-repeat;
  height: 95px;
  width: 95px;
}

.content-box {
  width: 65%;
  margin: auto;
}

.bar-text {
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .about-text-alignment {
    text-align: left;
  }
  .heading {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.8rem;
  }
  .bar-text {
    font-size: 1.2rem;
  }
  .content-box {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 991px) {
  .bar:before {
    background-size: 100% 100%;
    height: 48px;
    width: 48px;
  }
  .bar {
    background: linear-gradient(
      -135deg,
      transparent 34px,
      rgba(0, 0, 0, 0.6) 34px
    );
    padding: 2rem;
  }
}

/* ============ article css starts ============ */
.bar-block-design {
  background: linear-gradient(
    -135deg,
    transparent 66px,
    rgba(51, 51, 51, 0.9) 66px
  );
  color: white;
  padding: 3rem 6rem;
  position: relative;
}

.bar-block-design:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  background: url("../../public/assets/img/trans_corner.png") no-repeat;
  height: 90px;
  width: 90px;
}

.article-grid-container {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 2fr));
}

.article-card {
  background-color: var(--main-color);
  color: black;
}

.article-text {
  font-family: var(--main-font);

  font-size: 18px;
  font-weight: 500;
}

.article-img {
  width: 100%;
  height: 95vh;
  background-color: #000;
}
@media screen and (max-width: 768px) {
  .article-img {
    height: 60%;
  }
}

@media screen and (max-width: 427px) {
  .article-img {
    height: 50%;
  }
  .Fold:after {
    border-bottom: 45px solid var(--main-color);
    border-right: 45px solid #000;
  }
}

.Fold {
  width: 100%;
  background-color: #000;
  position: relative;
}

.Fold:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  border-bottom: 90px solid var(--main-color);
  border-right: 90px solid #000;
}

@media only screen and (max-width: 768px) {
  .bar-block-design {
    padding: 0.5rem;
    background: transparent;
  }
  .bar-block-design:before {
    content: "";
    background: transparent;
  }

  .article-grid-container {
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  }

  .Fold:after {
    border-bottom: 60px solid var(--main-color);
    border-right: 60px solid #000;
  }
}
@media screen and (max-width: 427px) {
  .article-img {
    height: 50%;
  }
  .Fold:after {
    border-bottom: 45px solid var(--main-color);
    border-right: 45px solid #000;
  }
}
@media only screen and (min-width: 768px) {
  .article-card {
    border: none;
    background-color: transparent;
  }

  .article-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(
      to bottom,
      rgba(254, 98, 134, 0.6),
      rgba(19, 39, 66, 1)
    ); */
    /* background-color: rgba(254, 197, 52, 0.9); */
    background-color: rgba(254, 196, 47, 0.8);
    z-index: 2;
    transition: 0.5s all;
    opacity: 0;
  }

  .article-card:hover::before {
    opacity: 1;
  }

  .article-card .article-card-text-block {
    position: absolute;
    z-index: 10;
    transition: 0.3s all;
    left: 50%;
    right: -35%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: #000;
    font-weight: bold;
  }

  .article-card:hover .article-card-text-block {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
  }
}

/* ============ article css ends ============ */

/* ===== footer css ===== */
.yellow-border {
  width: 100%;
  border-top: 5px solid var(--main-color);
}

.footer-header {
  font-size: 22px;
  color: white;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.scroll-to-top {
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 100;
  width: 3.5em;
  border-radius: 50%;
  cursor: pointer;
}

.scroll-to-top button {
  outline: none;
  padding: 1rem;
  border-radius: 50%;
}

/* ============= Data Card ============== */

.BookDataDiv {
  border: 2px solid var(--main-color);
  margin: 3rem 0 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BookDataLeft {
  padding: 0rem 3rem 0 1rem;
  font-family: var(--main-font);
  color: white;
}

.BookDataRight {
  max-width: 70%;
  background: var(--main-color);
  position: relative;
  padding: 2rem;
}

.BookDataRight:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 45px solid transparent;
  border-right: 45px solid #fec42f;
  border-bottom: 45px solid transparent;
  position: absolute;
  top: 50%;
  left: -43px;
  margin-top: -45px;
}

.BookDataRightBook {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.BookDataRightBook:hover {
  transform: scale(1.1);
}

.BookDataRightCntnt h4 {
  color: #000;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: var(--main-font);
}
.BookDataRightCntnt p,
.BookDataRightCntnt ul li {
  color: #000;
  font-size: 1rem;
  font-family: var(--main-font);
}

@media screen and (max-width: 768px) {
  .BookDataDiv {
    flex-direction: column;
  }

  .BookDataLeft {
    padding: 1rem;
  }

  .BookDataRight {
    max-width: 100%;
    flex-direction: column;
  }

  .BookDataRight:before {
    content: "";
    border: none;
  }

  .BookDataRightCntnt {
    padding: 0;
  }
}

/* ========== Book Review grid ========== */

.review-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.review-card {
  background-color: #000;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 2px;
  padding: 1rem 0;
}

.review-text-1 {
  text-align: center;
  color: #fff;
  /* font-size: 24px; */
  font-weight: bold;
  font-family: var(--main-font);
}

p.review-text-2 {
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 768px) {
  .review-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .review-card {
    background-color: transparent;
    padding: 6rem 0;
  }

  .review-text-body {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .review-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    background-color: var(--main-color);
    z-index: 2;
    transition: 0.5s all;
    opacity: 0;
  }

  .review-card:hover::before {
    opacity: 1;
  }

  p.review-text-2 {
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    right: -40%;
    color: black;
    transform: translate(-50%, -50%);
  }

  .review-card:hover p.review-text-2 {
    opacity: 1;
    visibility: visible;
    text-align: justify;
  }
}

/* photo collage */
.collage-grid-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #fff;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-grid-container {
  margin: 10px 25px;
}

.collage-grid-card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1.02);
}

.photo-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
}

@media screen and (min-width: 576px) {
  .custom-grid-container {
    margin: 50px 100px;
  }

  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}

/*================ Case studies ===================*/
.img-ribbon-text-position {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 75%;
  color: white;
}

.case-black-box {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  padding: 2rem 4rem;
  border-radius: 1rem;
}

.case-black-box h3 {
  font-size: 2rem;
  font-weight: bold;
  font-family: var(--main-font);
}

@media only screen and (max-width: 768px) {
  .img-ribbon-text-position {
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0, 0);
    width: 100%;
  }
  .case-black-box {
    padding: 1rem;
  }

  .case-black-box h3 {
    font-size: 1.2rem;
  }
}

/* page not found */
.page-not-found {
  height: 60vh;
  display: flex;
  align-items: center;
}
